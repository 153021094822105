<div class="wrapper">
  <div class="left-search-panel">
    <div class="left-search-panel-filters">
      <mat-form-field>
        <mat-icon matPrefix>search</mat-icon>
        <input
          [(ngModel)]="enteredSearchValue"
          (keyup)="onSearchTextChanged()"
          matInput
          type="text"
          placeholder="search here"
          class="search-input"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="userRole !== 'Coordinator'">
        <mat-label class="filter-label">Select Coordinator</mat-label>
        <mat-select
        class="option-select"
          [(ngModel)]="CoordinatorSelected"
          (ngModelChange)="onCoordinatorSelected()"
        >
          <mat-option class="option-list"
            *ngFor="let coordinator of Coordinator"
            [value]="coordinator._id"
          >
            {{ coordinator.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="userRole === 'Coordinator'">
        <mat-label class="filter-label">My Projects</mat-label>
        <mat-select
        class="option-select"
          [(ngModel)]="roleSelected"
          (ngModelChange)="onRoleSelected()"
        >
          <mat-option class="option-list" *ngFor="let item of coordinatorRole" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="filter-label">Select State</mat-label>
        <mat-select
        class="option-select"
          [(ngModel)]="StateSelected"
          (ngModelChange)="onStateSelected()"
        >
          <mat-option class="option-list" *ngFor="let state of States" [value]="state.name">
            {{ state.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        *ngIf="userRole !== 'Client' && userRole !== 'Coordinator'"
      >
        <mat-label class="filter-label">Select Client</mat-label>
        <mat-select
        class="option-select"
          [(ngModel)]="ClientSelected"
          (ngModelChange)="onClientSelected()"
        >
          <mat-option class="option-list" *ngFor="let client of Client" [value]="client._id">
            {{ client.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field appearance="outline">
      <mat-label>Select Payment</mat-label>
      <mat-select
        [(ngModel)]="PaymentSelected"
        (ngModelChange)="onPaymentSelected()"
      >
        <mat-option *ngFor="let payment of Payment" [value]="payment">
          {{ payment }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
      <!--  Date Range Filter-->
      <mat-form-field>
        <mat-label class="filter-label">From-To</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input
            matStartDate
            formControlName="start"
            placeholder="Start date"
            (dateChange)="onStartDateChanged()"
          />
          <input
            matEndDate
            formControlName="end"
            placeholder="End date"
            (dateChange)="onStartDateChanged()"
          />
        </mat-date-range-input>

        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
          >Invalid start date</mat-error
        >
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
          >Invalid end date</mat-error
        >
      </mat-form-field>
      <!-- <div
        class="import-holiday"
        *ngIf="userRole === 'Admin' || userRole === 'Super-Admin'" matTooltip="Date Format: DD-MM-YYYY"
      >
        <label for="upload_holiday">
          <img
            class="import-holiday-img"
            src="/assets/images/cloud_upload.svg"
          />
          <span class="import-holiday-label">Import Holidays</span></label
        >
        <input
          type="file"
          id="upload_holiday"
          (change)="onFileChange($event)"
          accept=".csv"
        />
      </div> -->
    </div>
    <div class="download-section">
      <button
        class="icon-button"
        matTooltip="Download Project List"
        (click)="downloadProjectList()"
      >
        <img class="icon" src="assets/images/admin-download.svg" />
      </button>
    </div>
  </div>

  <div class="right-search-panel">
    <div class="filter-section">
      <!-- <div class="filter-box">
        <img
          class="icon"
          src="assets/images/filter-icon.svg"
        />
        <label class="filter-count">12</label>
      </div>

      <span class="space-between">Filters</span>
    </div> -->
    </div>
    <div
      class="applied-filters"
      *ngIf="
        CoordinatorSelected ||
        ClientSelected ||
        StateSelected ||
        PaymentSelected ||
        enteredSearchValue ||
        create_date_gt ||
        create_date_lt ||
        roleSelected
      "
    >
      Applied Filters:
      <span *ngIf="CoordinatorSelected" (click)="clearIndividual('coordinator')"
        >Coordinators <mat-icon>clear</mat-icon></span
      >
      <span *ngIf="ClientSelected" (click)="clearIndividual('client')">
        Client <mat-icon>clear</mat-icon></span
      >
      <span *ngIf="StateSelected" (click)="clearIndividual('state')">
        States <mat-icon>clear</mat-icon></span
      >
      <span *ngIf="PaymentSelected" (click)="clearIndividual('')">
        Payment <mat-icon>clear</mat-icon></span
      >
      <span *ngIf="enteredSearchValue" (click)="clearIndividual('searchvalue')">
        search Filter <mat-icon>clear</mat-icon></span
      >
      <span *ngIf="create_date_gt" (click)="clearIndividual('startDate')"
        >Start Date <mat-icon>clear</mat-icon></span
      >
      <span *ngIf="create_date_lt" (click)="clearIndividual('endDate')">
        End Date <mat-icon>clear</mat-icon></span
      >
      <span *ngIf="roleSelected" (click)="clearIndividual('coordinatorRole')"
        >Role <mat-icon>clear</mat-icon></span
      >
      <button class="clear-filter-btn" (click)="clearFilter()">Clear</button>
    </div>
  </div>
</div>
