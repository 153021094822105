import { Component, Output, EventEmitter, Input, NgZone } from '@angular/core';
import { UserService } from '../../../admin/user/user.service';
import { DataSharingService } from '../../services/data-sharing.service';
import { ProjectService } from '../../services/project/project.service';
import { variableConstantFile } from 'src/assets/config/variableConstantFile';
import { ExtendRequestComponent } from '../../extend-request/extend-request.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectdetailService } from 'src/app/school-client/project-details/projectdetail.service';

@Component({
  selector: 'app-griha-project-detail-header',
  templateUrl: './griha-project-detail-header.component.html',
  styleUrls: ['./griha-project-detail-header.component.scss']
})
export class GrihaProjectDetailHeaderComponent {
  selectedValue: string = 'projectDetail';
  @Output() onDetailChanged = new EventEmitter<string>();
  @Output() onClickWorkshop = new EventEmitter<string>();
  @Output() onClickAssignUser = new EventEmitter<string>();
  @Output() updateProject = new EventEmitter<null>();
  @Input() isCoordinator: boolean = false;
  @Input() projectDetails: any;
  userRole: any;
  @Input() panelSide: string;
  result: any;
  steps:any = [];
  private holidayList: [] = [];
  variableConstantFile: any;
  criteriaEvaluators: any;
  IsEvaluatorsNotAssigned: boolean = false;
  projectCode: any;
  projectTimelineDates: any;

  constructor(private dataSharingService: DataSharingService,
    private projectService: ProjectService, private dialogBox: MatDialog, private projectDetailService: ProjectdetailService, private ngZone: NgZone) {
    this.variableConstantFile = variableConstantFile;
    this.getHoliday();
  }

  ngOnInit() {
    this.userRole = this.dataSharingService.clientRole;
    if(this.dataSharingService.clientRole === 'Admin' || this.dataSharingService.clientRole === 'Super-Admin'){
      this.userRole = this.panelSide;
    }
    console.log(this.userRole);
    if (this.projectDetails) {
      this.projectTimeline();
      this.criteriaData();
    }
    console.log(this.projectDetails);
    this.dataSharingService.holidayList = this.holidayList;
  }

  getHoliday() {
    this.projectService.getHolidayList().subscribe({
      next: (res) => {
        this.result = res;
        if (this.result.data) {
          this.holidayList = this.result.data;
          this.dataSharingService.holidayList = this.holidayList;
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  projectTimeline() {
    this.projectDetailService.timeLine(this.projectDetails?.project_code)
      .subscribe((res: any) => {
        this.ngZone.run(() => {
          console.log(res);
          this.projectTimelineDates = res.data;
          this.steps = this.projectTimelineDates;
          console.log(this.steps);
        });
      });
  }

  criteriaData() {
    this.projectService.getCriteriaData(this.projectDetails?._id).subscribe({
      next: res => {
        this.result = res;
        this.criteriaEvaluators = this.result.data;
        if (this.criteriaEvaluators) {
          for (let evaluator of this.criteriaEvaluators) {
            console.log(evaluator.evaluator);
            if (!evaluator.evaluator) {
              this.IsEvaluatorsNotAssigned = true;
            }
          }
        }
      },
      error: err => {
        console.error(err);
      }
    })
  }

  onWorkshopSchedule = (type: string) => {
    this.onClickWorkshop.emit(type)
  }

  assignUser = () => {
    this.onClickAssignUser.emit('assignUser');
  }

  onSelectionChange = (value: string) => {
    this.onDetailChanged.emit(value);
  }

  viewRequest() {
    const data = {
      projectDetails: this.projectDetails,
      isUser: this.userRole
    }
    const dialogRef = this.dialogBox.open(ExtendRequestComponent, { data });
    dialogRef.afterClosed().subscribe((res) => {
      console.log(res);
      if (res.update) {
        this.updateProject.emit();
      }
    });
  }

}
