import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  private GET_DOCS_URL='/projectdocs';
  private GET_PROJECT_DOCS_URL='/downloadprojectdocs';
  private GET_ONBOARDING ='/project/exportOnboardingJson';
  private GET_ONBOARDING_FILE = '/project/exportPdfFile'

  constructor(private http: HttpClient) {
    
   }

   getOnboarding(){
    return this.http.get(this.GET_ONBOARDING)
   }
   getOnboardingfILE(){
    return this.http.get(this.GET_ONBOARDING_FILE)
   }

   downloadFile(imgUrl:string){
    return this.http.get(imgUrl,{responseType:'blob'});
  }

  previewFile(imgUrl:string){
    return this.http.get(imgUrl, { responseType: 'blob' });
  }

  uploadFile(s3Url:string,file:any){
    const headers = { 'Content-Type': 'image/jpg' };
    return this.http.put(s3Url,file,{headers});
  }

  getDocumentsList(projectId:string,compliance_code:string){
    // console.log(projectId)
    // console.log('Comp',compliance_code)
    return this.http.get(this.GET_DOCS_URL+`/${projectId}/${compliance_code}`);
  }

  getDocumentsWithCriteriaCode(projectId:string,criteria_code:string){
    return this.http.get(this.GET_DOCS_URL+`/${projectId}/${criteria_code}`);
  }

  getDocumentsAsZip(projectCode:string,criteria_code:string){
    const params ={
      criteria_code :criteria_code
    }
    return this.http.get(this.GET_DOCS_URL+this.GET_PROJECT_DOCS_URL+'/'+projectCode,{params:params})
  }

  deleteDocument(projectId:string,doc_name:string){
    // console.log(projectId,doc_name);
    return this.http.delete(this.GET_DOCS_URL+`/${projectId}/${doc_name}`);
  }



  
}
