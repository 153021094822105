<div class="boarding-container">
  <h5>Rating Process</h5>
  <div class="template-container">
    <div class="template-header">
      <div class="header-left">
        <div class="header-heading">
          <h5>Welcome to Griha for Existing Schools</h5>
          <span>Let's take a look at how it works</span>
        </div>
        <div class="slide">
          <!-- Add slide content here if needed -->
          <div class="design">
            <div class="dash" *ngFor="let _ of [].constructor(content.length); let i = index"
              [ngClass]="{'active': i === currentpage}"></div>
          </div>
          <div class="pageno">
            {{currentpage + 1}} of {{content.length}}
          </div>
        </div>
      </div>
      <div class="header-right">
        <span (click)="downloadManual()">Download manual 📩</span>
      </div>
    </div>

    <div class="template-body">
      <div class="left-body">
        <div class="header-span-container">
          <h5>{{content[currentpage].heading}}</h5>
          <span>{{content[currentpage].sub_heading}}</span>
        </div>
        <p>{{content[currentpage].paragraph}}</p>
      </div>
      <div class="right-body">
        <img [src]="content[currentpage].url" alt="">
      </div>
    </div>
    <div class="template-footer">
      <button class="skip" (click)="onSkip()" *ngIf="currentpage != 9">Skip</button>
      <button class="previous" (click)="prevPage()" [disabled]="currentpage <= 0">Previous</button>
      <button class="next" (click)="nextPage()" [disabled]="currentpage >= content.length - 1" *ngIf="currentpage != 9">Next</button>
      <button class="finish" (click)="onSkip()" *ngIf="currentpage === 9">Finish</button>
    </div>

  </div>
</div>