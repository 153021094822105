import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floorAlphanumeric'
})
export class FloorAlphanumericPipe implements PipeTransform {

  transform(floor: number): string {
    switch (+floor) {
      case 0:
        return 'Ground Floor';
      case -1:
        return 'Basement';
      default:
        return `${this.original(floor)} Floor`;
    }
  }

  original(number: number): string {
    const lastDigit = number % 10;
    if (lastDigit === 1 && number !== 11) {
      return number + 'st';
    } else if (lastDigit === 2 && number !== 12) {
      return number + 'nd';
    } else if (lastDigit === 3 && number !== 13) {
      return number + 'rd';
    } else {
      return number + 'th';
    }
  }
}
