<div class="griha-project-detail-header__wrapper">
  <!-- <div class="project-detail-notification">
    <div
      *ngIf="IsEvaluatorsNotAssigned && userRole==='Coordinator' && projectDetails?.submission_status!==variableConstantFile.PROJECT_PENDING_SUBMISSION && (((projectDetails?.coordinator_target_date | dateToRemainingDays)-25)>0)"
      class="error-status">Assign Evaluator to the project. Delayed by {{(projectDetails?.coordinator_target_date |
      dateToRemainingDays)-25}} days.</div>
  </div> -->
  <div class="griha-project-detail-header__wrapper__icons">
    <div>
      <div class="timeline-container">
         <div *ngFor="let step of steps" class="arrow-box">
          <div class="upper-box" [ngStyle]="{'background-color': step.date != '' ? step.color : '#D9D9D9'}">
            {{ step.title }}
            <span *ngIf="step.date">{{step.date}}</span>
            <div class="line" [ngStyle]="{'background-color': step.date != '' ? step.color : '#D9D9D9'}"></div>
          </div>
          <div class="arrow" [ngStyle]="{'background-color': step.date != '' ? '#2e2d72' : '#D9D9D9'}">
            <svg class="dot" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <!-- Outer Circle (Boundary) -->
              <circle cx="12" cy="12" r="10" fill="none" [attr.stroke]="step.date != '' ? step.color : '#D9D9D9'"
                stroke-width="2" />
              <!-- Inner Circle -->
              <circle cx="12" cy="12" r="8" fill="white" />
            </svg>
          </div>
          @if(step.title == 'Workshop Optional'){
          <button class="schedule-button" *ngIf="userRole!=='Evaluator'"
            (click)="onWorkshopSchedule('workshop')">
            <img src="/assets/images/calendar_black.svg" />
          </button>
          }@else if (step.title == 'Site-visit (Optional)') {
          <button class="schedule-button" *ngIf="userRole!=='Evaluator'"
            (click)="onWorkshopSchedule('siteVisit')">
            <img src="/assets/images/calendar_black.svg" />
          </button>
          }@else if (step.title == 'Site-visit (Mandatory)') {
            <button 
  class="schedule-button" 
  [disabled]="projectDetails.submission_status === variableConstantFile.PROJECT_PENDING_SUBMISSION && (userRole==='Client')" 
  [matTooltip]="projectDetails.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION && (userRole==='Client')?'Mandatory Sitevisit can be scheduled once project submitted':''" 
  *ngIf="userRole !== 'Evaluator'"
  (click)="onWorkshopSchedule('mandatory_siteVisit')">
  <img src="/assets/images/calendar_black.svg" />
</button>

          
          }

        </div>
      </div>
    </div>







    <!-- Need to added this after testing done-->
    <!-- [disabled]="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION" 
          [ngClass]="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION?'project-detail-header--btn_workshop project-detail-header--btn_workshop_disabled':'project-detail-header--btn_workshop'" -->

    <!-- [disabled]="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION" 
          [matTooltip]="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION?'Mandatory Sitevisit can be scheduled once project submitted':''"
          [ngClass]="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION?'project-detail-header--btn_workshop project-detail-header--btn_workshop_disabled':'project-detail-header--btn_workshop'"-->
    <!-- *ngIf="projectDetails?.submission_status!==variableConstantFile.PROJECT_PENDING_SUBMISSION" -->
    <!-- <img *ngIf="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION"
            src="/assets/images/calendar_grey.svg" /> -->



    <!-- <button (click)="assignUser()" class="griha-project-detail-header__wrapper__icons--assign-user">
      <img src="/assets/images/person_add.svg" />
    </button> -->
    <!-- <div class="project-status-container"
      *ngIf="(userRole==='Evaluator' || userRole==='Coordinator') && (projectDetails?.submission_status!==variableConstantFile.PROJECT_STATUS_EVALUATED && projectDetails?.submission_status!==variableConstantFile.PROJECT_STATUS_CERTIFIED)">
      <div class="project-status-items" *ngIf="userRole==='Coordinator'">
        <div class="project-status-items-title"><img src="assets/images/alarm_success.svg"><span
            class="project-status-success">Overall project status : </span></div>
        <div class="project-status-details"
          *ngIf="projectDetails?.submission_status!==variableConstantFile.PROJECT_PENDING_SUBMISSION">
          <span
            *ngIf="(projectDetails?.coordinator_target_date | dateToRemainingDays)>=0">{{projectDetails?.coordinator_target_date
            | dateToRemainingDays | absoluteValue}} days left</span>
          <span
            *ngIf="(projectDetails?.coordinator_target_date | dateToRemainingDays)<0">{{projectDetails?.coordinator_target_date
            | dateToRemainingDays | absoluteValue}} days delay</span>
        </div>
        <div class="project-status-details"
          *ngIf="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION">
          Submission pending
        </div>
      </div>
      <div class="project-status-items">
        <div class="project-status-items-title"><img src="assets/images/alarm_error.svg"><span
            class="project-status-blue">Evaluation status : </span></div>
        <div class="project-status-details"
          *ngIf="(projectDetails?.submission_status===variableConstantFile.PROJECT_SUBMIT_TO_EVALUATOR)">
          <span *ngIf="IsEvaluatorsNotAssigned">Not assigned yet</span>
          <span
            *ngIf="(projectDetails?.evaluator_target_date | dateToRemainingDays)>=0 && !IsEvaluatorsNotAssigned">{{projectDetails?.evaluator_target_date
            | dateToRemainingDays | absoluteValue}} days left</span>
          <span
            *ngIf="(projectDetails?.evaluator_target_date | dateToRemainingDays)<0 && !IsEvaluatorsNotAssigned">{{projectDetails?.evaluator_target_date
            | dateToRemainingDays | absoluteValue}} days delay</span>
          <img (click)="viewRequest()" src="assets/images/history.svg" alt=""
            *ngIf="!IsEvaluatorsNotAssigned && (projectDetails?.submission_status===variableConstantFile.PROJECT_SUBMIT_TO_EVALUATOR)">
        </div>
        <div class="project-status-details"
          *ngIf="projectDetails?.submission_status!==variableConstantFile.PROJECT_SUBMIT_TO_EVALUATOR">
          Submission pending
        </div>
      </div>
    </div>
    <div class="project-status-container"
      *ngIf="(userRole==='Evaluator' || userRole==='Coordinator') && (projectDetails?.submission_status===variableConstantFile.PROJECT_STATUS_EVALUATED || projectDetails?.submission_status===variableConstantFile.PROJECT_STATUS_CERTIFIED)">
      Project Approved
    </div> -->
  </div>

</div>