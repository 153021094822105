import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import moment from 'moment';
import { WorkshopService } from '../../services/workshop/workshop.service';
import { App, Project, ScheduleWorkshop } from '../../../model/workshop.model';
import {
  GrihaCalendarComponent,
  SelectedDate,
} from '../../calendar/griha-calendar/griha-calendar.component';
import { ProjectDetails } from '../../../model/project.details.module';
import { WorkshopStatus } from '../../../model/workshop.status.enum';
import { WorkshopSiteVisitModel } from '../../../model/workshop/workshopSiteVisitModel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppUtils } from '../../apputils/app.utils';
import { SiteVisitRequestModel } from '../../../model/sitevisit.request.model';
import { SiteVisitService } from '../../services/site-visit.service';
import { error } from 'protractor';
import { AppConstants } from '../../apputils/appConstant';
import { DataSharingService } from '../../services/data-sharing.service';
import { LoaderService } from '../../services/app-loader/loader.service';

@Component({
  selector: 'app-meeting-scheduler',
  templateUrl: './meeting-scheduler.component.html',
  styleUrls: ['./meeting-scheduler.component.scss'],
})
export class MeetingSchedulerComponent implements OnInit {
  attendeesName: string;
  workshopValue: WorkshopSiteVisitModel;
  from: string;
  filters = ['Workshop', 'Site Visit', 'Site Visit Report'];
  projects = ['In-Progress', 'Completed', 'Approved'];
  projectData: ProjectDetails;
  workshopSection: string = 'Workshop';
  attendeesNames: string[] = [];
  now: string = '';
  workshopWeek: string;
  today: string = '';
  isCheckedWorkshop = true;
  isCheckedSiteVisit = false;
  checkBoxColor = '#1f79e2';
  representativeEmail: string;
  representativeNumber: string;
  representativeEmailValidations: boolean = false;
  representativeNumberValidations: boolean = false;
  isWorkshopOrBothChecked: boolean = true;
  workShopSiteVisit: WorkshopSiteVisitModel;
  selectedDateValue: SelectedDate;
  removal: boolean = false;
  action = 'Send Request';
  siteVisitType: string = 'OPTIONAL';
  canScheduleWorkshop: boolean = true;
  @Output() onSelectedDate = new EventEmitter<SelectedDate>();
  bothSchedule: number = 0;
  @ViewChild(GrihaCalendarComponent) grihaCalendar: GrihaCalendarComponent;
  hasSiteVisit: boolean = false;
  workshopData: any;
  siteVisitData: any;
  projectDetails: any;
  appId: string;
  isAttendeeInvalid: boolean = true;
  buttonInvalid: boolean;

  constructor(
    public dialogRef: MatDialogRef<MeetingSchedulerComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private workshopService: WorkshopService,
    private siteVisitService: SiteVisitService,
    private snackBar: MatSnackBar,
    private dataSharingService: DataSharingService,
    private loaderService: LoaderService
  ) {
    this.dataSharingService.projectDetails$.subscribe((newData) => {
      this.projectData = newData;
    });
    // this.projectData = data.project;
    this.workshopValue =
      data.which !== 'siteVisit' ? data.workshop : data.siteVisitSch;
    this.workshopData = data.workshop;
    this.siteVisitData = data.siteVisitSch;
    this.from = data.which;
    this.hasSiteVisit = data.hasSiteVisit;
    this.siteVisitType = data.site_visit_type;
    this.isCheckedSiteVisit = this.from === 'siteVisit';
    console.log(`SiteVisitType ${this.siteVisitType}`);
    console.log(this.workshopData, this.siteVisitData);
    this.isCheckedWorkshop = !this.isCheckedSiteVisit;

    this.today = moment().format('MMM D, YYYY');
    this.refreshUI();
    this.ngOnInit();
  }

  ngOnInit = (): void => {
    this.dataSharingService.projectDetails$.subscribe((data) => {
      this.projectDetails = data;
      this.appId = this.projectDetails.app._id;
    });
    if (this.workshopValue) {
      if (!this.data.shouldViewOrCreate) {
        this.selectedDateValue =
          AppUtils.getSelectedDateFromWorkshopOrSiteVisit(this.workshopValue);
        console.log(
          `Checkworkshop ${this.workShopSiteVisit} And Preselect Value ${this.selectedDateValue}`
        );
        this.representativeEmail =
          this.workshopValue.client_representative_email;
        this.representativeNumber =
          this.workshopValue.client_representative_phone;
        this.attendeesNames = this.workshopValue.attendees;
        this.action = 'ReSchedule';
      }
      this.removal = true;
    } else {
      this.workshopService.fetchPossibleDates().subscribe({
        next: (response) => {
          console.info(`Workshop response :: ${response.data}`);
          this.action = 'Send Request';
          this.selectedDateValue = AppUtils.getSelectedDateOnCreation(
            response.data
          );
          this.grihaCalendar.onApiCallCompletion(this.selectedDateValue);
        },
        error: (err) => {
          console.error(`Workshop ${err}`);
        },
      });
      this.removal = true;
    }
    console.log(
      `Workshop ${this.workshopValue} And Preselected Value ${this.selectedDateValue}`
    );
    this.refreshUI();
    this.checkAttendee();
  }

  checkAttendee(){
    if(this.attendeesNames?.length > 0){
      this.isAttendeeInvalid = false;
    }
  }

  onChangeFilter = (filter: string) => {
    console.log(`Filter ${filter}`);
    this.workshopSection = filter;
  };

  onClickScheduleApi = (event: any) => {
    const app = new App();
    app.id = this.appId; //need to discuss
    const project = new Project();
    project.id = this.projectData._id;
    console.log(
      `StartDate :: ${event.startDate}, ClientRepEmail :: ${event.clientRepEmail}`
    );
    console.log(
      `ClientRepPhone :: ${event.clientRepPhone}, Participants :: ${event.participants}`
    );
    let startDate, endDate;
    if (this.selectedDateValue) {
      startDate = this.selectedDateValue.selectedStartWeek.format('YYYY-MM-DD');
      console.log(`Meeting - StartDate ${startDate}`);
      console.log(`Meeting - StartDate ${startDate}`);
      this.now = moment().format('YYYY-MM-DD');
      const description = this.projectData.description
        ? this.projectData.description.length === 0
          ? 'This is workshop request'
          : this.projectData.description
        : 'This is workshop request';
      if (
        AppUtils.isValidEmail(event.clientRepEmail) &&
        AppUtils.isValidPhone(event.clientRepPhone) &&
        AppUtils.isValidAttendees(event.participants)
      ) {
        const workshopBody = new ScheduleWorkshop(
          app,
          project,
          this.projectData.name,
          description,
          startDate,
          event.clientRepEmail,
          event.clientRepPhone,
          WorkshopStatus.WORKSHOP_STATUS_FEE_APPROVAL_PENDING,
          event.participants
        );
        if (this.action === 'ReSchedule') {
          workshopBody.status =
            WorkshopStatus.WORKSHOP_STATUS_PENDING_CONFIRMATION;
          console.log(`Workshop Id ${this.data.workshop._id}`);
          this.loaderService.isLoading.next(true);
          this.workshopService
            .updateWorkshop(workshopBody, this.data.workshop._id)
            .subscribe({
              next: (res) => {
                console.log(`Workshop has been created ${res}`);
                this.loaderService.isLoading.next(false);
                this.snackBar.open(
                  'Workshop has been updated successfully.',
                  'info',
                  {
                    duration: 5 * 1000,
                    panelClass: ['info'],
                  }
                );
                const both = this.isCheckedWorkshop && this.isCheckedSiteVisit;
                if (both) {
                  this.bothSchedule++;
                  if (this.bothSchedule == 2) {
                    if (res) {
                      const data = res.data;
                      this.workshopData = data;
                      this.onClickedCancel({
                        status: res.message,
                        res: data,
                        workshopData: this.workshopData,
                        siteVisitData: this.siteVisitData,
                      });
                    } else {
                      this.onClickedCancel({
                        status: 'update',
                        res: this.data.workshop._id,
                      });
                    }
                  }
                } else {
                  if (res) {
                    const data = res.data;
                    this.onClickedCancel({
                      status: res.message,
                      res: data,
                      workshopData: this.workshopData,
                      siteVisitData: this.siteVisitData,
                    });
                  } else {
                    this.onClickedCancel({
                      status: 'update',
                      res: this.data.workshop._id,
                    });
                  }
                }
              },
              error: (err) => {
                this.loaderService.isLoading.next(false);
                this.onClickedCancel({ status: 'fail', res: err });
              },
            });
        } else {
          this.loaderService.isLoading.next(true);
          this.workshopService.scheduleWorkshop(workshopBody).subscribe({
            next: (res) => {
              console.log(`Workshop has been created ${res}`);
              this.loaderService.isLoading.next(false);
              this.snackBar.open(
                'Workshop has been scheduled successfully.',
                'info',
                {
                  duration: 5 * 1000,
                  panelClass: ['info'],
                }
              );
              const data = res.data;
              this.workshopData = data;
              console.log(this.workshopData);
              const both = this.isCheckedWorkshop && this.isCheckedSiteVisit;
              if (both) {
                this.bothSchedule++;
                if (this.bothSchedule == 2) {
                  this.onClickedCancel({
                    status: res.message,
                    res: data,
                    workshopData: this.workshopData,
                    siteVisitData: this.siteVisitData,
                  });
                }
              } else {
                this.onClickedCancel({
                  status: res.message,
                  res: data,
                  workshopData: this.workshopData,
                  siteVisitData: this.siteVisitData,
                });
              }
            },
            error: (err) => {
              this.loaderService.isLoading.next(false);
              this.snackBar.open(err, 'info', {
                duration: 5 * 1000,
                panelClass: ['info'],
              });
              this.onClickedCancel({ status: 'fail', res: err });
            },
          });
        }
      } else {
        this.snackBar.open('Invalid email / phone, Please retry.', 'info', {
          duration: 5 * 1000,
          panelClass: ['info'],
        });
      }
    } else {
      this.snackBar.open('Please select correct week to schedule.', 'info', {
        duration: 5 * 1000,
        panelClass: ['info'],
      });
    }
  };

  onClickScheduleSiteVisitApi = (event: any) => {
    const app = new App();
    app.id = this.appId; //need to discuss
    const project = new Project();
    project.id = this.projectData._id;
    console.log(
      `StartDate :: ${event.startDate}, ClientRepEmail :: ${event.clientRepEmail}`
    );
    console.log(
      `ClientRepPhone :: ${event.clientRepPhone}, Participants :: ${event.participants}`
    );
    let startDate, endDate;
    if (this.selectedDateValue) {
      startDate = this.selectedDateValue.selectedStartWeek.format('YYYY-MM-DD');
      console.log(`Meeting - StartDate ${startDate}`);
      console.log(`Meeting - StartDate ${startDate}`);
      this.now = moment().format('YYYY-MM-DD');
      const description = this.projectData.description
        ? this.projectData.description.length === 0
          ? 'This is siteVisite request'
          : this.projectData.description
        : 'This is siteVisite request';
      if (
        AppUtils.isValidEmail(event.clientRepEmail) &&
        AppUtils.isValidPhone(event.clientRepPhone)
      ) {
        const siteVisitRequest = new SiteVisitRequestModel(
          app,
          project,
          this.projectData.name,
          description,
          startDate,
          this.siteVisitType,
          event.clientRepEmail,
          event.clientRepPhone
        );
        if (this.action === 'ReSchedule') {
          siteVisitRequest.status =
            WorkshopStatus.WORKSHOP_STATUS_PENDING_CONFIRMATION;
          console.log('SiteVisit Id', this.data.siteVisitSch._id);
          this.loaderService.isLoading.next(true);
          this.siteVisitService
            .updateSiteVisit(siteVisitRequest, this.data.siteVisitSch._id)
            .subscribe({
              next: (res) => {
                this.snackBar.open('Site visit updated successfully.', 'info', {
                  duration: 5 * 1000,
                  panelClass: ['info'],
                });
                this.loaderService.isLoading.next(false);
                const both = this.isCheckedWorkshop && this.isCheckedSiteVisit;
                if (both) {
                  this.bothSchedule++;
                  if (this.bothSchedule == 2) {
                    if (res) {
                      const data = res.data;
                      this.siteVisitData = data;
                      this.onClickedCancel({
                        status: res.message,
                        res: data,
                        workshopData: this.workshopData,
                        siteVisitData: this.siteVisitData,
                      });
                    } else {
                      this.onClickedCancel({
                        status: 'update',
                        res: this.data.siteVisitSch._id,
                      });
                    }
                  }
                } else {
                  if (res) {
                    const data = res.data;
                    this.onClickedCancel({
                      status: res.message,
                      res: data,
                      workshopData: this.workshopData,
                      siteVisitData: this.siteVisitData,
                    });
                  } else {
                    this.onClickedCancel({
                      status: 'update',
                      res: this.data.siteVisitSch._id,
                    });
                  }
                  this.loaderService.isLoading.next(false);
                }
              },
              error: (err) => {
                this.snackBar.open(err, 'error', {
                  duration: 5 * 1000,
                  panelClass: ['error'],
                });
                this.onClickedCancel({ status: err, res: err });
                this.loaderService.isLoading.next(false);
              },
            });
        } else {
          this.loaderService.isLoading.next(true);
          this.siteVisitService.scheduleSitVisit(siteVisitRequest).subscribe({
            next: (res) => {
              console.log(`SiteVisit has been created ${res}`);
              this.snackBar.open(
                'SiteVisit has been scheduled successfully.',
                'info',
                {
                  duration: 5 * 1000,
                  panelClass: ['info'],
                }
              );
              this.loaderService.isLoading.next(false);
              const data = res.data;
              this.siteVisitData = data;
              console.log(this.siteVisitData);
              const both = this.isCheckedWorkshop && this.isCheckedSiteVisit;
              if (both) {
                this.bothSchedule++;
                if (this.bothSchedule == 2) {
                  this.onClickedCancel({
                    status: res.message,
                    res: data,
                    workshopData: this.workshopData,
                    siteVisitData: this.siteVisitData,
                  });
                }
              } else {
                this.onClickedCancel({
                  status: res.message,
                  res: data,
                  workshopData: this.workshopData,
                  siteVisitData: this.siteVisitData,
                });
              }
            },
            error: (err) => {
              console.log(`${err}`);
              this.snackBar.open(err ? err : 'Something went wrong!', 'error', {
                duration: 5 * 1000,
                panelClass: ['error'],
              });
              this.onClickedCancel({ status: 'fail', res: err });
              this.loaderService.isLoading.next(false);
            },
          });
        }
      } else {
        this.snackBar.open('Invalid email / phone, Please retry.', 'info', {
          duration: 5 * 1000,
          panelClass: ['info'],
        });
      }
    }
  };

  onClickedCancel = (event: any) => {
    this.dialogRef.close(event);
  };

  onNegativeButtonClicked = (action: string) => {
    this.dialogRef.close();
  };

  onPositiveButtonClicked = (action: string) => {};

  onCheckedSiteVisit = (event: any) => {
    console.log('Selected to', event);
    console.log(this.isCheckedSiteVisit, this.isCheckedWorkshop);
    if(!this.isCheckedSiteVisit && !this.isCheckedWorkshop){
      this.buttonInvalid = true
      console.log('true')
    }else{
      this.buttonInvalid = false
      console.log('false')
    }
    this.isCheckedSiteVisit = !!event.target.checked;
    this.refreshUI();
    this.enterAttendee(); 
  };

  onCheckedWorkshop = (event: any) => {
    console.log('Workshop Selected to ', event);
    if(!this.isCheckedSiteVisit && !this.isCheckedWorkshop){
      this.buttonInvalid = true
      console.log('true')
    }else{
      this.buttonInvalid = false
      console.log('false')
    }
    this.isCheckedWorkshop = !!event.target.checked;
    this.refreshUI();
    this.enterAttendee();
  };

  private refreshUI() {
    this.isWorkshopOrBothChecked =
      (this.isCheckedSiteVisit && this.isCheckedWorkshop) ||
      this.isCheckedWorkshop;
    if (this.isWorkshopOrBothChecked) {
      this.isAttendeeInvalid = true;
    } else {
      this.isAttendeeInvalid = false;
    }
  }

  onCancelSchedule = () => {
    this.onClickedCancel('schedule cancel');
  };

  onClickSchedule = () => {
    const value: any = {
      startDate: this.selectedDateValue,
      clientRepEmail: this.representativeEmail,
      clientRepPhone: this.representativeNumber,
      participants: this.attendeesNames,
    };
    const both = this.isCheckedWorkshop && this.isCheckedSiteVisit;
    if (both) {
      this.onClickScheduleApi(value);
      this.onClickScheduleSiteVisitApi(value);
    } else if (this.from === 'siteVisit' || this.isCheckedSiteVisit) {
      this.onClickScheduleSiteVisitApi(value);
    } else {
      this.onClickScheduleApi(value);
    }
  };

  onKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (this.attendeesName && emailRegex.test(this.attendeesName)) {
        this.attendeesNames.push(this.attendeesName);
        console.log(`Enter key pressed ${this.attendeesName}`);
        this.attendeesName = '';
      } else {
        if(!this.isCheckedSiteVisit && !this.isCheckedWorkshop){
        this.snackBar.open('Please select workshop/sitevisit ', 'info', {
          duration: 5 * 1000,
          panelClass: ['info'],
        });
        }else{

          this.snackBar.open('Please enter valid email and phone number ', 'info', {
            duration: 5 * 1000,
            panelClass: ['info'],
          });
        }
      }
    }
  };

  enterAttendee() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (this.attendeesName && emailRegex.test(this.attendeesName)) {
      this.isAttendeeInvalid = false;
      this.attendeesNames.push(this.attendeesName);
      console.log(`Enter key pressed ${this.attendeesName}`);
      this.attendeesName = '';
    } else if (this.attendeesName === '') {
      this.isAttendeeInvalid = false;
    } else {
      if(!this.isCheckedSiteVisit && !this.isCheckedWorkshop){
      this.snackBar.open('Please select workshop/sitevisit ', 'info', {
        duration: 5 * 1000,
        panelClass: ['info'],
      });
      }else{

        this.snackBar.open('Please enter valid email and phone number ', 'info', {
          duration: 5 * 1000,
          panelClass: ['info'],
        });
      }
    }
  }

  onSelectedWeek = (selectedDate: SelectedDate) => {
    console.log(`selectedDates ${selectedDate}`);
    console.log(`Selected Date in Meeting Scheduler ${selectedDate}`);
    this.selectedDateValue = selectedDate;
    this.onSelectedDate.emit(selectedDate);
    if (this.attendeesNames.length > 0) {
      console.log(this.attendeesName, this.attendeesNames);
      this.isAttendeeInvalid = false;
    }
  };

  onDeletedAttendees = (name: string) => {
    AppUtils.removeFirst(this.attendeesNames, name);
    if (this.attendeesNames.length > 0) {
      console.log(this.attendeesName, this.attendeesNames);
      this.isAttendeeInvalid = false;
    } else {
      this.isAttendeeInvalid = true;
    }
  };

  getTitle = () => {
    let title = 'Optional Workshop/SiteVisit';
    if (this.from === 'siteVisit' && this.siteVisitType == 'MANDATORY') {
      title = 'Mandatory SiteVisit';
      this.canScheduleWorkshop = false;
    }
    return title;
  };
}
