export const dev_config = {
    authDomain: "https://grihadevauth.paloalto-soft.com",
    clientId: "orOuBgSYFx45znJxfC3xgTWjaVpXSFoD",
    apiDomain: "https://wivqam17l7.execute-api.ap-south-1.amazonaws.com/dev",
    // apiDomain:"https://e7wmcmv9n3.execute-api.ap-south-1.amazonaws.com/dev",
    version: "v1",
    app: "svagriha",
    svagriha_url: 'https://dev2svagriha.paloalto-soft.com',
    griha_url: 'https://dev2griha.paloalto-soft.com',
    old_griha_base: 'https://dev2griha.paloalto-soft.com',
    backRedirection: {
        admin: '',
        client: '',
        coordinator: '',
    },
    audience: "https://dev-5onb5ctiywl2tjy2.us.auth0.com/api/v2/",
    pushNotiPublicKey:'BEzOtKR25BhDnJKoSL2zZweof4shJNieub14khZ8_AknTetdkuK23aWYRECiydc2Nfbkwx-mo3aj2Nu4etecAR4'
}
