<div class="extend-container">
    <div class="extend-container-header">
        <div class="extend-container-header-title">Extension Request</div>
        <img matDialogClose src="assets/images/close.svg" alt="">
    </div>
    <div class="extend-container-main-container">
        <div class="extend-container-main-deadline-container">
            <div>Current Deadline - </div>
            <div>{{data.projectDetails.evaluator_target_date | dateFormat}}<span>({{data.projectDetails.evaluator_target_date | dateToRemainingDays | absoluteValue}} days remaining)</span></div>
        </div>
        <div>
            <div *ngIf="data.isUser==='Coordinator'">
                <span *ngIf="data.projectDetails.evaluator_propose_requested">The evaluator has requested a {{data.projectDetails.evaluator_propose_day}} day extension for evaluation</span>
                <span *ngIf="!data.projectDetails.evaluator_propose_requested">No request from evaluator</span>
            </div>
            <div *ngIf="data.isUser==='Evaluator'" class="extend-container-days-selection-container">
                <div class="extend-container-days-selection" *ngIf="!data.projectDetails.evaluator_propose_requested">
                    <div class="extend-container-days-selection-title">Extra Days from Now</div>
                <mat-form-field>
                    <mat-label>Select Days</mat-label>
                    <mat-select [(ngModel)]="selectedDays">
                        <mat-option *ngFor="let day of days" [value]="day">
                            {{day}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
                <div *ngIf="data.projectDetails.evaluator_propose_requested">
                    The evaluator has requested a {{data.projectDetails.evaluator_propose_day}} day extension for evaluation
                </div>
            </div>
        </div>
        <!-- <div *ngIf="data.isUser==='Evaluator'" class="extend-container-extension-checkbox-container">
            <input type="checkbox" [(ngModel)]="allowAfterDeadline">
            Allow extension request when the deadline expires
        </div> -->
        <div class="extend-container-btn-container">
            <button *ngIf="data.isUser==='Evaluator' && !data.projectDetails.evaluator_propose_requested" [disabled]="selectedDays<=0" (click)="sendRequest()" class="btn-custom btn-primary-custom">
                Send Request
            </button>
            <button *ngIf="data.isUser==='Coordinator' && data.projectDetails.evaluator_propose_requested" (click)="onReject()" class="btn-custom btn-secondary">Reject</button>
            <button *ngIf="data.isUser==='Coordinator' && data.projectDetails.evaluator_propose_requested" (click)="onAccept()" class="btn-custom btn-primary-custom">Accept</button>
        </div>
    </div>
</div>