import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from './shared/services/app-loader/loader.service';
import { AuthService } from '@auth0/auth0-angular';

import { DOCUMENT } from '@angular/common';
import { ProjectAndPersonalDetailService } from './shared/services/client-detail/project-and-personal-detail.service';
import { DataSharingService } from './shared/services/data-sharing.service';
import { PushNotificationService } from './shared/services/push-notification/push-notification.service';
import { environment } from 'src/environments/environment';
import { SwPush } from '@angular/service-worker';
import { DataService } from './coordinator/data.service';
import { NotificationService } from './shared/services/notification.service';
import { Subscription, take } from 'rxjs';
import { ProjectService } from './shared/services/project/project.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewChecked {
  idTokenData: any;
  accessTokenData: string;
  isAuthenticated: boolean = false;
  emailId: string | undefined;

  result: any;
  datas: any;
  rawIdToken: any;

  userInfo: any;
  role: any;
  notifications: any;

  accessTokenSub: Subscription;
  idTokenSub: Subscription;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public loaderService: LoaderService,
    private router: Router,
    public auth: AuthService,
    private cdRef: ChangeDetectorRef,
    private clientService: ProjectAndPersonalDetailService,
    private pushNotificationService: PushNotificationService,
    private swPush: SwPush,
    private dataService: DataService,
    private notificationService: NotificationService,
    private dataSharingService: DataSharingService,
    private projectService: ProjectService
  ) {
    console.log('Trying Authentication');
    this.loaderService.isLoading.next(true);
    try {
      // Use auth.isAuthenticated$.subscribe to get the current authentication status
      if (auth.isAuthenticated$) {
        console.log('Authenticated');
        this.accessTokenSub = this.auth.getAccessTokenSilently().subscribe({
          next: (accessToken) => {
            {
              this.accessTokenData = accessToken;
              if (this.accessTokenData) {
                console.log('Setting access token in local storage');
                localStorage.setItem('accessToken', this.accessTokenData);
                this.idTokenSub = this.auth.idTokenClaims$
                  .pipe(take(1))
                  .subscribe((idToken) => {
                    if (idToken) {
                      console.log('Setting id token in localStorage', idToken);
                      localStorage.setItem('idToken', idToken.__raw);

                      // Use the 'email' claim to extract the user's email
                      const emailId = idToken?.['username'];

                      if (emailId && this.userInfo === undefined) {
                        this.getUser(emailId);
                      }
                    } else {
                      console.log('ID token not found');
                      this.loaderService.isLoading.next(false);
                    }
                  });
              } else {
                console.log('Access token not found');
                this.loaderService.isLoading.next(false);
              }
            }
          },
          error: (error)=>{
            console.log(error);
            this.isAuthenticated = false;
            window.location.href = environment.griha_url;
          }
        });
      } else {
        console.log('Redirect to login page');
        this.isAuthenticated = false;
        this.loaderService.isLoading.next(false);
        window.location.href = environment.griha_url;
      }
    } catch (error) {
      console.log('Exception in sva-griha in authentication --> ' + error);
    }
  }

  ngOnInit() {
    this.pushNotificationService.notificationClick();
    this.swPush.messages.subscribe((messages: any) => {
      console.log('message from Push Notification' + JSON.stringify(messages));
      if (messages.notification.data.event === 'DOWNLOAD') {
        const decodedURL = decodeURIComponent(messages.notification.data.url);
        // const url = window.URL.createObjectURL(decodedURL);
        console.log(decodedURL);
        this.downloadFromURL(decodedURL,messages.notification.data.file_name);
        // const a = document.createElement('a');
        // a.href = decodedURL;
        // a.download = messages.notification.data.file_name; // Set the desired file name
        // document.body.appendChild(a);
        // a.click();
        // window.URL.revokeObjectURL(decodedURL);
      } else if (messages.notification.data.event === 'NOTIFICATION') {
        this.notificationService.fetchNotifications().subscribe({
          next: (response) => {
            this.notifications = response.data;
            this.dataSharingService.updateNotificationData(this.notifications);
          },
          error: (err) => {
            console.log('error', err);
          },
        });
      } else if (messages.notification.data.event === 'LOGIN') {
      }
    });
  }

  downloadFromURL(url: string,file_name:string) {
    console.log(file_name);
    this.projectService.downloadFile(url).subscribe({
      next: (data: Blob) => {
        // Create a new Blob object and URL
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const blobUrl = window.URL.createObjectURL(blob);
    
        // Create an anchor element and trigger download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = file_name; // Set the filename for the downloaded file
        document.body.appendChild(link); // Append to the body
        link.click(); // Trigger the download
        document.body.removeChild(link); // Remove the link from the DOM
    
        // Optionally, revoke the URL after some time to free up resources
        setTimeout(() => window.URL.revokeObjectURL(blobUrl), 10000);
      },
      error: (error) => {
        console.error('Error downloading file:', error);
      },
    });
  }

  subscribeToPush() {
    // Notification.requestPermission().then((permission) => {
    //   if (permission === 'granted') {
    //     console.log('Persmission Granted');
    console.log('subscribing notificaion')
    this.pushNotificationService.pushSubscription();
    // } else {
    //   console.log('User denied permission for push notifications.');
    // }
    // });
  }

  getUser(email: string) {
    this.clientService.getUserByUsername(email).subscribe({
      next: (res) => {
        console.log('new pannel', res);
        this.datas = res;
        this.userInfo = this.datas.data[0];
        this.role = this.userInfo?.role_name;
        console.log(this.userInfo, this.role);
        localStorage.setItem('userInfo', this.userInfo._id);
        this.dataService.setUserId({ CoordinatorId: this.datas.data[0]._id });
        console.log('user id', this.datas.data[0]._id);
        this.dataService.setUserName({ name: this.datas.data[0].name });
        console.log('userInfo', this.datas.data[0].name);
        this.dataSharingService.clientEmail = this.datas.data[0]?.email;
        this.dataSharingService.clientName = this.datas.data[0]?.name;
        this.dataSharingService.clientRole = this.datas.data[0]?.role_name;
        console.log(this.dataSharingService.clientRole);
        this.dataSharingService.clientProfile = this.datas.data[0]?.photoUrl;
        this.dataSharingService.clientId = this.datas.data[0]?._id;
        this.dataSharingService.clientUserName = this.datas.data[0]?.username;
        this.requestPermission();
        this.getNotification();
      },
      error: (err) => {
        console.error(err);
        this.loaderService.isLoading.next(false);
      },
    });
  }

  requestPermission() {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted');
          // if (
          //   this.userInfo.notify_subs === undefined ||
          //   this.userInfo.notify_subs === null
          // ) {
            this.subscribeToPush();
          // }
        } else if (permission === 'denied') {
          console.log('Notification permission denied');
        } else if (permission === 'default') {
          console.log('Notification permission dismissed');
        }
      });
    }
  }

  getNotification() {
    this.notificationService.fetchNotifications().subscribe({
      next: (response) => {
        this.notifications = response.data;
        console.log('user Notification', this.notifications);
        this.dataSharingService.updateNotificationData(this.notifications);
        this.isAuthenticated = true;
        this.loaderService.isLoading.next(false);
      },
      error: (err) => {
        console.log('error', err);
        this.loaderService.isLoading.next(false);
      },
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.accessTokenSub.unsubscribe();
    this.idTokenSub.unsubscribe();
  }
}
