import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';

@Injectable()
export class BasePathInterceptor implements HttpInterceptor {
  rawIdToken: any;
  idTokenData: any;
  accessTokenData: any;

  constructor(private auth: AuthService, private router: Router) {
    console.log('BasePathInterceptor:: constructor called');
  }

  ngOnInit() {
    console.log(
      `BasePathInterceptor:: ngOnInit called ${this.auth.isAuthenticated$}`
    );
    // if (this.auth.isAuthenticated$) {
    //   this.auth.idTokenClaims$.subscribe(idToken => {
    //     this.idTokenData = idToken
    //     this.rawIdToken = idToken?.__raw
    //     console.log('idToken', this.idTokenData)

    //     this.auth.getAccessTokenSilently().subscribe(accessToken => {
    //       this.accessTokenData = accessToken
    //       console.log('accessToken', this.accessTokenData)
    //     });

    //   });
    // }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const domain = environment.apiDomain;
    const version = environment.version;
    const app = environment.app;

    let url = `${domain}/${app}/${version}`;

    const idToken = localStorage.getItem('idToken') || '';
    const accessToken = localStorage.getItem('accessToken');
    // console.log(`Base URL :: ${url}`)
    if (req.url.includes('http://')) {
      const authRequest = req.clone({
        setHeaders: {
          id_token: idToken,
          Authorization: `Bearer ${accessToken}`,
        }
      })
      return next.handle(authRequest);
    }
    else if (req.url.includes('https://')) {
      return next.handle(req);
    } else {
      const modifiedReq = req.clone({
        url: url + req.url,
        setHeaders: {
          id_token: idToken,
          Authorization: `Bearer ${accessToken}`,

          // or 'User-Agent': 'YourCustomUserAgent',
        },
      });

      // Pass the modified request to the next interceptor or the backend
      return next.handle(modifiedReq).pipe(
        tap(
          (event) => {},
          (error) => {
            // Handle other errors here if needed
            if (error.status === 403) {
              // Redirect to the ForbiddenComponent or any other component you want
              console.log('inside basepath 403');
              this.router.navigate(['/forbidden']);
            }
          }
        )
      );
    }
  }
}
