import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  BlockScrollStrategy,
  Overlay,
  OverlayModule,
} from '@angular/cdk/overlay';
import {
  MAT_SELECT_SCROLL_STRATEGY,
  MatSelectModule,
} from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import {
  MatSidenavContainer,
  MatSidenavModule,
} from '@angular/material/sidenav';
import { MatChipsModule } from '@angular/material/chips';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule, TooltipComponent } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SideMenuComponent } from './sidemenu/sidemenu.component';
import { TableComponent } from './table/table.component';
import { BreadcrumbService } from './services/breadcrumb.service';
import { MatBadgeModule } from '@angular/material/badge';
import { FilterComponent } from './filter/filter.component';
import { TableSearchComponent } from './tablesearch/table-search.component';
import { ActionColumnComponent } from './action-colum/action-column.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationComponent } from './notification/notification.component';
import { GrihaCalendarComponent } from './calendar/griha-calendar/griha-calendar.component';
import { GrihaProjectDetailHeaderComponent } from './project/project-detail-header/griha-project-detail-header.component';
import { MeetingSchedulerComponent } from './project/meeting-scheduler/meeting-scheduler.component';
import { SiteVisitMeetingComponent } from './project/site-visit/site-visit-meeting/site-visit-meeting.component';
import { WorkshopMeetingComponent } from './project/workshop/workshop-meeting/workshop-meeting.component';
import { MeetingAttendeesComponent } from './project/attendents/meeting-attendees/meeting-attendees.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BlankWorkshopViewComponent } from './project/blankworkshop/blank-workshop-view/blank-workshop-view.component';
import { MeetingRowComponent } from './project/meeting-scheduler/meetingitem/metting-row/meeting-row.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { WorkshopSiteVisitRequestStatusComponent } from './project/worksho-site-visit-status/workshop-site-visit-request-status.component';
import { ViewWorkshopSiteVisitComponent } from './project/view-workshop-site-visit/view-workshop-site-visit.component';
import { RomanNumeralPipe } from './pipes/roman-numeral.pipe';
import { FloorAlphanumericPipe } from './pipes/floor-alphanumeric.pipe';
import { FileDragNDropDirective } from './directive/file-drag-n-drop.directive';
import { HolidayUploadComponent } from './holiday/holiday-upload/holiday-upload.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { ExtendRequestComponent } from './extend-request/extend-request.component';
import { DateToRemainingDaysPipe } from './pipes/date-to-remaining-days.pipe';
import { ResetConfirmComponent } from './project/reset-confirm/reset-confirm.component';
import { DateFormatWtTimePipe } from './pipes/date-format-wt-time.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { NgxGaugeModule } from 'ngx-gauge';
import { ChartComponent } from './chart/chart.component';
import { AbsoluteValuePipe } from './pipes/absolute-value.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OnboardingComponentComponent } from './onboarding-component/onboarding-component.component';
import {CustomComplianceNamePipe} from './pipes/custom-compliance-name.pipe'

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [
    SearchBarComponent,
    SideMenuComponent,
    TableComponent,
    FilterComponent,
    TableSearchComponent,
    ActionColumnComponent,
    NotificationComponent,
    GrihaCalendarComponent,
    GrihaProjectDetailHeaderComponent,
    MeetingSchedulerComponent,
    SiteVisitMeetingComponent,
    WorkshopMeetingComponent,
    MeetingAttendeesComponent,
    BlankWorkshopViewComponent,
    MeetingRowComponent,
    MeetingAttendeesComponent,
    TextEditorComponent,
    BreadcrumbComponent,
    WorkshopSiteVisitRequestStatusComponent,
    ViewWorkshopSiteVisitComponent,
    RomanNumeralPipe,
    FloorAlphanumericPipe,
    FileDragNDropDirective,
    HolidayUploadComponent,
    DateFormatPipe,
    CurrencyFormatPipe,
    ExtendRequestComponent,
    DateToRemainingDaysPipe,
    ResetConfirmComponent,
    DateFormatWtTimePipe,
    CapitalizePipe,
    TimeFormatPipe,
    TruncatePipe,
    OnboardingComponentComponent
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatCheckboxModule,
    DragDropModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatBadgeModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    MatMenuModule,
    MatTabsModule,
    NgxGaugeModule,
    ChartComponent,
    AbsoluteValuePipe,
    NgbModule,
    CustomComplianceNamePipe
  ],
  exports: [
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    DragDropModule,
    MatBadgeModule,
    MatSidenavContainer,
    MatToolbarModule,
    SideMenuComponent,
    TableComponent,
    FilterComponent,
    TableSearchComponent,
    NotificationComponent,
    GrihaCalendarComponent,
    GrihaProjectDetailHeaderComponent,
    MeetingSchedulerComponent,
    WorkshopMeetingComponent,
    SiteVisitMeetingComponent,
    MeetingAttendeesComponent,
    AngularEditorModule,
    TextEditorComponent,
    BreadcrumbComponent,
    RomanNumeralPipe,
    FileDragNDropDirective,
    DateFormatPipe,
    CurrencyFormatPipe,
    DateToRemainingDaysPipe,
    FloorAlphanumericPipe,
    CapitalizePipe,
    NgxGaugeModule,
    ChartComponent,
    DateFormatWtTimePipe,
    AbsoluteValuePipe,
    TimeFormatPipe,
    NgbModule,
    TruncatePipe,
    OnboardingComponentComponent,
    CustomComplianceNamePipe
  ],
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance },
    BreadcrumbService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
