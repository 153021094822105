import { Component, Input } from '@angular/core';
import { NgxGaugeModule } from 'ngx-gauge';

@Component({
  selector: 'app-chart',
  standalone: true,
  imports: [NgxGaugeModule],
  templateUrl: './chart.component.html',
  styleUrl: './chart.component.scss',
})


export class ChartComponent {
  @Input() criteriaNo :any 
  @Input() title : any ;
  @Input() gaugeType:any ;
  @Input() gaugeValue :any;
  @Input() gaugeLabel = '';
  @Input() gaugeAppendText = '';
  @Input() size:any ;
  @Input() thick :any;
  @Input()  min: any;
  @Input() max: any;
  @Input() foregroundColor :any;
  @Input() backgroundColor :any;
  thresholdConfig:any
  markerConfig:any
  
  ngOnInit(): void {
    
    this.thresholdConfig = {
      "0": {
        "color": "green",
        "bgOpacity": 0.1
      },
      "4": {
        "color": "orange",
        "bgOpacity": 0.1
      },
      "7.5": {
        "color": "red",
        "bgOpacity": 0.1
      }
    }

    this.markerConfig = {
      [this.min] : { color: '#555', size: 8, label: (+this.min), type: 'line'},
      
      [(+this.min + (+this.max))/2]: { color: '#555', size: 4, label: (+this.min + (+this.max))/2, type: 'line'},
      
      [this.max]: { color: '#555', size: 8, label:( +this.max), type: 'line'},
  
  }
  // console.log(this.markerConfig)
}
}
