import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customComplianceName',
  standalone: true
})
export class CustomComplianceNamePipe implements PipeTransform {

  transform(value: string): string {
    // Use regex to remove the part between _V1_ and _G0
    const regex = /_V\d+_.*?_/;
    return value.replace(regex, '_');
  }

}
