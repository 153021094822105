import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import moment from 'moment';
// @ts-ignore
import * as range from 'lodash.range';
import { ProjectService } from '../../services/project/project.service';
import { DataSharingService } from '../../services/data-sharing.service';

export interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
}

export class SelectedDate {
  private _selectedStartWeek: moment.Moment;
  private _selectedEndWeek: moment.Moment;
  private _selectedDate: string;

  get selectedStartWeek(): moment.Moment {
    return this._selectedStartWeek;
  }

  set selectedStartWeek(value: moment.Moment) {
    this._selectedStartWeek = value;
  }

  get selectedEndWeek(): moment.Moment {
    return this._selectedEndWeek;
  }

  set selectedEndWeek(value: moment.Moment) {
    this._selectedEndWeek = value;
  }

  get selectedDate(): string {
    return this._selectedDate;
  }

  set selectedDate(value: string) {
    this._selectedDate = value;
  }
}

@Component({
  selector: 'app-griha-calendar',
  templateUrl: './griha-calendar.component.html',
  styleUrls: ['./griha-calendar.component.scss'],
})
export class GrihaCalendarComponent implements OnInit {
  private DATE_FORMAT: string = 'YYYY-MM-DD';
  @Input() selectedDateValue: SelectedDate;
  @Input() shouldApply: boolean;
  @Input() disableWeekSelection: boolean;
  @Output() onSelectedDate = new EventEmitter<SelectedDate>();
  public currentDate: moment.Moment;
  dropdownPosition: any;
  public namesOfDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  public weeks: Array<CalendarDate[]> = [];
  private holidayList: [] = [];

  public selectedDate: any;
  public selectedStartWeek: any;
  public selectedEndWeek: any;
  public show: boolean;
  private startDate: moment.Moment;
  private endDate: moment.Moment;
  private isAdminSelected: boolean = false;

  @ViewChild('calendar', { static: true }) calendar: any;
  @ViewChild('inputBox') inputBox: ElementRef;

  @HostListener('document:click', ['$event'])
  clickOut(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.show = false;
    }
  }

  constructor(
    private eRef: ElementRef,
    private dataSharing:DataSharingService
  ) {
    console.info(`Disable week selection ${this.disableWeekSelection}`);
  }

  ngOnInit() {
    console.info(`Initialize Griha Calender ${this.disableWeekSelection}`);
    this.holidayList=this.dataSharing.holidayList;
    this.currentDate = moment();
    if (
      this.selectedDateValue &&
      this.selectedDateValue.selectedStartWeek &&
      this.selectedDateValue.selectedEndWeek
    ) {
      this.selectedStartWeek = this.selectedDateValue.selectedStartWeek;
      this.selectedEndWeek = this.selectedDateValue.selectedEndWeek;
      this.selectedDate = this.selectedDateValue.selectedDate;
    } else {
      this.selectedStartWeek = moment().weekday(-7);
      this.selectedEndWeek = moment().weekday(-1);
      this.selectedDate = `${this.selectedStartWeek.format(
        this.DATE_FORMAT
      )} To ${this.selectedEndWeek.format(this.DATE_FORMAT)}`;
    }
    if (this.selectedDateValue) {
      this.startDate = this.selectedDateValue.selectedStartWeek.subtract(
        1,
        'days'
      );
      this.endDate = this.selectedDateValue.selectedEndWeek.add(1, 'days');
      const month = Math.ceil(
        this.selectedStartWeek.diff(this.currentDate, 'months')
      );
      console.log(`Current view ${month}`);
      this.currentDate = moment(this.currentDate).add(month, 'months');
    }

    this.generateCalendar();
  }

  public onApiCallCompletion = (selectedDateValue: SelectedDate) => {
    if (
      selectedDateValue &&
      selectedDateValue.selectedStartWeek &&
      selectedDateValue.selectedEndWeek
    ) {
      this.selectedStartWeek = selectedDateValue.selectedStartWeek;
      this.selectedEndWeek = selectedDateValue.selectedEndWeek;
      this.selectedDate = selectedDateValue.selectedDate;

      const currentDateMonth = this.currentDate.month() + 1;
      const currentMonth = this.selectedStartWeek.month() + 1;
      if (currentDateMonth != currentMonth) {
        this.currentDate = moment(this.currentDate).add(1, 'months');
      }
    }
    this.generateCalendar();
  };

  private generateCalendar(): void {
    const dates = this.fillDates(this.currentDate);
    const weeks = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    console.log(`elligibleWeel ${weeks}`);
    this.weeks = weeks;
  }

  private fillDates(currentMoment: moment.Moment) {
    // index first day of month in week
    const firstDayOfMonth = moment(currentMoment).startOf('month').day();
    // index last day of month  in week
    const lastDayOfMonth = moment(currentMoment).endOf('month').day();

    const firstDayOfGrid = moment(currentMoment)
      .startOf('month')
      .subtract(firstDayOfMonth, 'days');
    // get last start of week + week
    const lastDayOfGrid = moment(currentMoment)
      .endOf('month')
      .subtract(lastDayOfMonth, 'days')
      .add(7, 'days');

    const startCalendar = firstDayOfGrid.date();

    return range(
      startCalendar,
      startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'days')
    ).map((date) => {
      const newDate = moment(firstDayOfGrid).date(date);
      return {
        today: this.isToday(newDate),
        selected: this.disableWeekSelection
          ? this.isSelectedSingleDate(newDate)
          : this.isSelected(newDate),
        mDate: newDate,
      };
    });
  }

  public prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'months');
    this.generateCalendar();
  }

  public nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'months');
    this.generateCalendar();
  }

  public isDisabledMonth(date: any): boolean {
    const today = moment();
    return moment(date).isAfter(today, 'months');
  }

  private isToday(date: moment.Moment): boolean {
    return (
      moment().format('YYYY-MM-DD') === moment(date).format(this.DATE_FORMAT)
    );
  }

  private isSelected(date: moment.Moment): boolean {
    let isWeekend = moment(date).day() === 0 || moment(date).day() === 6 || this.holidayList.some((holiday) => moment(holiday).isSame(date, 'day'));
    return (
      !isWeekend &&
      ((moment(date).isBefore(this.selectedEndWeek) &&
        moment(date).isAfter(this.selectedStartWeek)) ||
        moment(date.format('YYYY-MM-DD')).isSame(
          this.selectedStartWeek.format(this.DATE_FORMAT)
        ) ||
        moment(date.format('YYYY-MM-DD')).isSame(
          this.selectedEndWeek.format(this.DATE_FORMAT)
        )) &&
      (moment(date).isAfter(moment()) || moment(date).isSame(moment()))
    );
  }

  private isSelectedSingleDate(date: moment.Moment): boolean {
    return this.selectedDate === moment(date).format(this.DATE_FORMAT);
  }

  public isSatSundayOrCurrentWeekPlusThreeWeekOrPreviousDay(
    date: moment.Moment
  ): boolean {
    // console.log(`Date currentDate ${this.selectedStartWeek} calendar date ${moment(date)} isBefore  ${moment(date).isBefore(this.selectedStartWeek)}`)
    // console.log(`isBefore ${moment(date).isBefore(this.selectedStartWeek)}`)
    // this.holidayList.find((element) => {
    //   console.log(
    //     'holiday date',
    //     moment(element).toLocaleString(),
    //     'selected date',
    //     moment(date).toLocaleString()
    //   );
    // });
    // return (moment(date).day() === 0 || moment(date).day() === 6) ||  moment(date).isBefore(this.selectedStartWeek);

    // Check if the day is Sunday (0) or Saturday (6)
    if (moment(date).day() === 0 || moment(date).day() === 6) {
      return true;
    }

    // Check if the date is before the selectedStartWeek
    // if (moment(date).isBefore(this.selectedStartWeek)) {
    //   return true;
    // }
    const currentDate = moment();
    let futureDate = moment(currentDate).add(3, 'weeks').subtract(1, 'days');
    if (moment(date).isBefore(futureDate)) {
      return true;
    }

    // Check if the date is in the holidayList
    if (
      this.holidayList.some((holiday) => moment(holiday).isSame(date, 'day'))
    ) {
      return true;
    }

    // If none of the above conditions are met, return false
    return false;
  }

  public isSelectedMonth(date: moment.Moment): boolean {
    return moment(date).isSame(this.currentDate, 'month');
  }

  public isSelectedWeek(date: moment.Moment): boolean {
    const isWeekend = moment(date).day() === 0 || moment(date).day() === 6 || this.holidayList.some((holiday) => moment(holiday).isSame(date, 'day'));
    const isAfterWeekStartDate = moment(date).isAfter(this.startDate);
    console.log(`IsInBetween ${isAfterWeekStartDate}`);
    return isAfterWeekStartDate && !isWeekend;
  }

  public selectableWeek = (date: moment.Moment) => {
    const currentDate = moment();
    let futureDate = moment(currentDate).add(3, 'weeks');
    const day = moment(this.currentDate).day();
    if (day !== 0 && day !== 6) {
      futureDate = moment(futureDate).add(day, 'days');
    }

    const daysValue = futureDate.startOf('days');
    console.log(`Days value ${daysValue}`);
    return daysValue.isBefore(date);
  };
  public selectDate(date: CalendarDate) {
    this.selectedStartWeek = moment(date.mDate);
    const offsetDays =
      moment(date.mDate).day() === 0
        ? 1
        : moment(date.mDate).day() === 6
        ? 2
        : 0;
    console.log(`offset Days ${offsetDays}`);
    this.selectedStartWeek = moment(date.mDate).add(offsetDays);
    console.log(`Start date ${this.selectedStartWeek.mDate}`);

    this.selectedEndWeek = this.selectedStartWeek;

    for (let i = 0; i < 4; i++) {
      this.selectedEndWeek = moment(this.selectedEndWeek).add(1, 'days');
      console.log('Current selectedEndWeek' ,this.selectedEndWeek);
      console.log('Holiday : ',this.holidayList.some((holiday) => moment(holiday).isSame(this.selectedEndWeek, 'day')))
      console.log(moment(this.selectedEndWeek).day());
      let offsetDays =
        moment(this.selectedEndWeek).day() === 0
          ? 0
          : moment(this.selectedEndWeek).day() === 6
          ? 2
          : 0;
      offsetDays+=this.holidayList.some((holiday) => moment(holiday).isSame(this.selectedEndWeek, 'day'))?1:0;
      if (offsetDays !== 0) {
        this.selectedEndWeek = moment(this.selectedEndWeek).add(
          offsetDays,
          'days'
        );
        const holidayOffsetDays=this.holidayList.some((holiday) => moment(holiday).isSame(this.selectedEndWeek, 'day'))?1:0;
        this.selectedEndWeek=moment(this.selectedEndWeek).add(holidayOffsetDays,'days')
      }
    }

    console.log(`End date ${this.selectedEndWeek}`);

    this.selectedDate = `${this.selectedStartWeek.format(
      'YYYY-MM-DD'
    )} To ${this.selectedEndWeek.format(this.DATE_FORMAT)}`;
    const selectedValue = new SelectedDate();
    selectedValue.selectedDate = this.selectedDate;
    selectedValue.selectedStartWeek = this.selectedStartWeek;
    selectedValue.selectedEndWeek = this.selectedEndWeek;
    this.onSelectedDate.emit(selectedValue);
    this.generateCalendar();
    this.show = !this.show;
  }

  public selectDateDailyCal(date: CalendarDate) {
    this.selectedDate = moment(date.mDate).format(this.DATE_FORMAT);
    this.generateCalendar();
    this.show = !this.show;
    this.isAdminSelected = true;
    const selectedValue = new SelectedDate();
    selectedValue.selectedDate = this.selectedDate;
    selectedValue.selectedStartWeek = this.selectedStartWeek;
    selectedValue.selectedEndWeek = this.selectedEndWeek;
    this.onSelectedDate.emit(selectedValue);
  }

  calCalendarPos(): string {
    let positionClass = 'below_top__value';
    if (this.inputBox) {
      const inputBoxRect = this.inputBox.nativeElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - inputBoxRect.bottom;
      console.log(`SpaceBelow ${spaceBelow}`);
      positionClass =
        spaceBelow > 200 ? 'below_top__value' : 'above_top__value';
    }
    return positionClass;
  }

  getDayNameByDate = (date: moment.Moment) => {
    return this.namesOfDays[moment(date).day()];
  };
}
