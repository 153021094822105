<div class="action-wrapper">
  <mat-icon *ngIf="canView" class="action-wrapper__icon-button view-icon icon" matTooltip="View" (click)="onViewAction()">visibility</mat-icon>
    <img *ngIf="canEdit" class="action-wrapper__icon-button" matTooltip="Edit" (click)="onEditAction()" class="icon" src="assets/images/btn_edit_action.svg" />
    <img *ngIf="canDelete" class="action-wrapper__icon-button icon" matTooltip="Delete" (click)="onDeleteAction()" src="assets/images/btn_delete_action.svg" />
    <mat-icon class="action-wrapper__icon-button" *ngIf="canInitiateProject" (click)="initiateProject()">slideshow</mat-icon>
     <mat-icon class="action-wrapper__icon-button add-person-icon" matTooltip="Assign User" *ngIf="canAssignCoordinator" (click)="assignCoordinator()">person_add</mat-icon>
    <mat-icon class="action-wrapper__icon-button history-icon" matTooltip="View Logs" *ngIf="canViewLogs" (click)="viewLogs()">history</mat-icon>
    <mat-icon *ngIf="canViewSettings && (userRole==='Super-Admin' || userRole==='Admin')" class="action-wrapper__icon-button setting-icon icon" matTooltip="View Settings" (click)="onViewSettings()">settings</mat-icon>
</div>

<mat-drawer #drawer class="activity-log" mode="side" position="end">
  <button mat-button (click)="closeLogs()" class="activity-btn">
    Close
  </button>

  <p class="head">Activity History</p>
  <app-view-activity-logs [projectData]="rowData.project_code" *ngIf="isLogsOpened"></app-view-activity-logs>
</mat-drawer>