import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../notification.service';
import { DataSharingService } from '../data-sharing.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(private httpClient: HttpClient,
    private swPush:SwPush) {}
  private subscribe_URL='/usernotification/subscribe';
  notifications:any;
  
  pushSubscription(){
    // if ('Notification' in window) {
    //   Notification.requestPermission()
    //     .then((permission) => {
    //       if (permission === 'granted') {
    //         console.log('Notification permission granted');
    console.log('inside push subscription')
            this.swPush
              .requestSubscription({
                serverPublicKey: environment.pushNotiPublicKey, // Get this key from your server
              })
              .then(sub => {
                // Send the subscription to your server
                console.log('Notification Subscription:', sub);
                this.httpClient.post(this.subscribe_URL,sub).subscribe({
                  next:res=>{
                    console.log(res);
                  },
                  error:err=>{
                    console.error(err);
                  }
                });
              })
              .catch(err => {
                console.error('Could not subscribe to notifications', err);
              });
    //       } else if (permission === 'denied') {
    //         console.warn('Notification permission denied');
    //       } else if (permission === 'default') {
    //         console.warn('Notification permission dismissed');
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Error requesting notification permission:', error);
    //     });
    // } else {
    //   console.error('Notification API is not supported in this browser');
    // }
  }

  notificationClick(){
    this.swPush.notificationClicks.subscribe(arg=>{
      console.log('clicked on notification',arg.notification.data.url)
      if(arg.notification.data.event==='DOWNLOAD'){
        const decodedURL=decodeURIComponent(arg.notification.data.url);
        // const url = window.URL.createObjectURL(decodedURL);
        console.log(decodedURL);
        const a = document.createElement('a');
        a.href = decodedURL;
        a.download = arg.notification.data.file_name; // Set the desired file name
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(decodedURL);
      }else{
        window.open(arg.notification.data.url)
      }
    })
  }
}
