import { Injectable } from '@angular/core';
import {catchError, Observable, throwError} from 'rxjs';
import {ResponsePacket} from '../../../model/ResponsePacket';
import {HttpClient} from '@angular/common/http';
import {ScheduleWorkshop} from '../../../model/workshop.model';
import {WorkshopSiteVisitModel} from '../../../model/workshop/workshopSiteVisitModel';

@Injectable({
  providedIn: 'root'
})
export class WorkshopService {
  private FETCH_WORKSHOP = '/workshop?_id=#';
  private FETCH_WORKSHOP_BY_PROJECT_ID = '/workshop?project=#';
  private SCHEDULE_WORKSHOP_URL = '/workshop';
  private RESCHEDULE_WORKSHOP_URL = '/workshop/reschedule/#'
  private REJECT_WORKSHOP_REQUEST = '/workshop/#'
  private CONFIRM_WORKSHOP_REQUEST = '/workshop/confirm/#'
  private PROPOSE_WORKSHOP_REQUEST = '/workshop/propose/#'
  private POSSIBLE_WORKSHOP_DATE_REQUEST = '/workshop/getpossibleschedulingdates'
  private UPDATE_WORKSHOP_REQUEST = '/workshop/#'

  constructor(private http: HttpClient) { }

  fetchById = (id: string) : Observable<ResponsePacket<WorkshopSiteVisitModel[]>> => {
    return this.http.get<ResponsePacket<WorkshopSiteVisitModel[]>>(this.FETCH_WORKSHOP.replace('#', id))
      .pipe(
        catchError(this.handleError)
      );
  }

  fetchByProjectId = (id: string) : Observable<ResponsePacket<WorkshopSiteVisitModel[]>> => {
    return this.http.get<ResponsePacket<WorkshopSiteVisitModel[]>>(this.FETCH_WORKSHOP_BY_PROJECT_ID.replace('#', id))
      .pipe(
        catchError(this.handleError)
      );
  }


  scheduleWorkshop = (scheduleReq: ScheduleWorkshop) : Observable<ResponsePacket<WorkshopSiteVisitModel>> => {
    return this.http.post<ResponsePacket<WorkshopSiteVisitModel>>(this.SCHEDULE_WORKSHOP_URL, scheduleReq)
      .pipe(
        catchError(this.handleError)
      );
  }

  reScheduleWorkshop = (startDate: string, id: string) : Observable<ResponsePacket<WorkshopSiteVisitModel>> => {
    return this.http.patch<ResponsePacket<WorkshopSiteVisitModel>>(this.RESCHEDULE_WORKSHOP_URL.replace('#', id), {
      start_date: startDate
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateWorkshop = (scheduleReq: ScheduleWorkshop, id: string) : Observable<ResponsePacket<WorkshopSiteVisitModel>> => {
    return this.http.patch<ResponsePacket<WorkshopSiteVisitModel>>(this.UPDATE_WORKSHOP_REQUEST.replace('#', id), scheduleReq)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateStatus = (id: string, workshopStatus: string,isAccepted: boolean) : Observable<ResponsePacket<WorkshopSiteVisitModel>> => {
    console.log(`Workshop IsAccepted Sent ${isAccepted} `)
    return this.http.patch<ResponsePacket<WorkshopSiteVisitModel>>(this.REJECT_WORKSHOP_REQUEST.replace('#', id), {status: workshopStatus, is_accepted:isAccepted})
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmStatus = (id: string, date: string, coord1Id : string, coord2Id: string) : Observable<ResponsePacket<WorkshopSiteVisitModel>> => {
    return this.http.patch<ResponsePacket<WorkshopSiteVisitModel>>(this.CONFIRM_WORKSHOP_REQUEST.replace('#', id), {
      status: 'CONFIRMED',
      confirm_date : date,
      coordinator: {"_id": coord1Id},
      coordinator2: {"_id":coord2Id}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  proposeStatus = (id: string, date: string, coord1Id : string, coord2Id: string) : Observable<ResponsePacket<WorkshopSiteVisitModel>> => {
    return this.http.patch<ResponsePacket<WorkshopSiteVisitModel>>(this.PROPOSE_WORKSHOP_REQUEST.replace('#', id), {
      proposed_date : date,
      coordinator: {"_id": coord1Id},
      coordinator2: {"_id":coord2Id}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  fetchPossibleDates = () : Observable<ResponsePacket<any>> => {
    return this.http.get<ResponsePacket<any>>(this.POSSIBLE_WORKSHOP_DATE_REQUEST)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError = (error: any) => {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.error;
    } else {
      // server-side error
      errorMessage = error.error.error;
    }
    return throwError(errorMessage);
  }
}
