import moment from 'moment';
import {WorkshopSiteVisitModel} from '../../model/workshop/workshopSiteVisitModel';
import {SelectedDate} from '../calendar/griha-calendar/griha-calendar.component';
import {WorkshopStatus} from '../../model/workshop.status.enum';

export class AppUtils {

  static isValidEmail(emailString: string): boolean {
    // Define a regular expression pattern for a valid email address
    const emailPattern = /^(?!.*\.{2})(?!.*[_]\.)[a-zA-Z0-9]+([._%-]?[a-zA-Z0-9]+)*(?:@(?!.*\.{2})(?!.*\._)[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,})(?:\s*;\s*[a-zA-Z0-9]+([._%-]?[a-zA-Z0-9]+)*(?:@(?!.*\.{2})(?!.*\._)[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}))*[;,]?$/;

    // Split the input string by commas or semicolons
    const emails = emailString.split(/[;,]/);

    const validEmails = [];

    // Iterate through the split strings and validate each part
    emails.forEach((email) => {
      const trimmedEmail = email.trim();
      if (emailPattern.test(trimmedEmail)) {
        validEmails.push(trimmedEmail);
      }
    });

    return validEmails.length === emails.length;
  }

  static isValidPhone(phone: string): boolean {
    const phonePattern = /^(\+91[0-9]{10}|\d{10})$/;
    return phonePattern.test(phone);
  }

  static isValidAttendees(participants: string[]) {
    let isValidEmail = true;
    if (participants.length === 0) {
      isValidEmail = false;
    } else {
      isValidEmail = participants.every(email => this.isValidEmail(email));
    }
    return isValidEmail;
  }

  static buildDateRangeAndRemainingDays = (workshop: WorkshopSiteVisitModel) : any => {
    console.log(workshop);
    const startDay = workshop.selected_days[0];
    const endDay = workshop.selected_days[4];
    const momentStartDate = moment(startDay, 'YYYY-MM-DD');
    const momentEndDate = moment(endDay, 'YYYY-MM-DD');
    const startMonthAndDate = momentStartDate.format('MMM DD');
    const endMonthAndDate = momentEndDate.format('MMM DD');
    const year = momentStartDate.format('YYYY');
    const  workshopWeek = `(${startMonthAndDate} - ${endMonthAndDate}, ${year})`;
    const today = moment().format("YYYY-MM-DD");
    const remainingDays = momentEndDate.diff(today, 'days');
    return {workshopWeek : workshopWeek, remainingDays: remainingDays}
  }

  static clientRequestedWeek = (workshop: WorkshopSiteVisitModel) : string => {
    const startDay = workshop.selected_days[0];
    const endDay = workshop.selected_days[4];
    const momentStartDate = moment(startDay, 'YYYY-MM-DD');
    const momentEndDate = moment(endDay, 'YYYY-MM-DD');
    const startMonthAndDate = momentStartDate.format('MMM DD');
    const endMonthAndDate = momentEndDate.format('MMM DD');
    const year = momentStartDate.format('YYYY');
    return`(${startMonthAndDate} - ${endMonthAndDate}, ${year})`;
  }

  static buildNormalDateRangeAndRemainingDays = (workshop: WorkshopSiteVisitModel) : any => {
    const startDay = workshop.selected_days[0];
    const proposalDate = moment(startDay);
    return AppUtils.getWorkshopWeekAndRemainingDays(proposalDate)
  }

  static buildProposalDateRangeAndRemainingDays = (workshop: WorkshopSiteVisitModel) : any => {
    const proposalDate = moment(workshop.proposed_date);
    return AppUtils.getWorkshopWeekAndRemainingDays(proposalDate)
  }

  static buildConfirmDateRangeAndRemainingDays = (workshop: WorkshopSiteVisitModel) : any => {
    const confirmDate = moment(workshop.confirm_date);
    return AppUtils.getWorkshopWeekAndRemainingDays(confirmDate);
  }

  private static getWorkshopWeekAndRemainingDays(date: any) {
    const today = moment().format("MMM DD, YYYY");
    const  workshopWeek = moment(date).format('MMM DD, YYYY');
    const remainingDays = moment(date).diff(today, 'days');
    return {workshopWeek : workshopWeek, remainingDays: remainingDays}
  }

  static getStartAndEndDateInMoment = (workshop: WorkshopSiteVisitModel) : any => {
    const startDay = workshop.selected_days[0];
    const endDay = workshop.selected_days[4];
    const momentStartDate = moment(startDay, 'YYYY-MM-DD');
    const momentEndDate = moment(endDay, 'YYYY-MM-DD');
    return {startDate : momentStartDate, endDate: momentEndDate}
  }

  static getSelectedDateFromWorkshopOrSiteVisit = (workshop: WorkshopSiteVisitModel) : any => {
    const selectedDate = new SelectedDate();
    const startDay = workshop.selected_days[0];
    const endDay = workshop.selected_days[4];
    const momentStartDate = moment(startDay, 'YYYY-MM-DD');
    const momentEndDate = moment(endDay, 'YYYY-MM-DD');
    selectedDate.selectedDate = `${startDay} To ${endDay}`;
    selectedDate.selectedStartWeek = momentStartDate;
    selectedDate.selectedEndWeek = momentEndDate;
    return selectedDate;
  }

  public static getSelectedDateOnCreation = (selectedDates: string[]): SelectedDate  => {
    const selectedDate = new SelectedDate();
    const startDay = selectedDates[0];
    const endDay = selectedDates[4];
    const momentStartDate = moment(startDay, 'YYYY-MM-DD');
    const momentEndDate = moment(endDay, 'YYYY-MM-DD');
    selectedDate.selectedDate = `${startDay} To ${endDay}`;
    selectedDate.selectedStartWeek = momentStartDate;
    selectedDate.selectedEndWeek = momentEndDate;
    return selectedDate;
  }

  public static removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  public static stringToMMDDYYY = (dateString: string):string => {
    const momentDate =  moment(dateString, 'YYYY-MM-DD');
    return momentDate.format('MMM DD YYYY');
  }


  public static refreshUi = (siteVisitOrWorkshop: WorkshopSiteVisitModel) => {
    console.log(`Available project workshop ${siteVisitOrWorkshop}`);
    const result = AppUtils.buildDateRangeAndRemainingDays(siteVisitOrWorkshop);
    const confirmedProposedDate = AppUtils.buildProposalDateRangeAndRemainingDays(siteVisitOrWorkshop);
    const confirmedDate = AppUtils.buildConfirmDateRangeAndRemainingDays(siteVisitOrWorkshop);
    console.log(`Workshop status :: ${siteVisitOrWorkshop.status}`);
    let txtActionButton = '';
    let status = '';
    let canReschedule = false;
    switch (siteVisitOrWorkshop.status) {
      case WorkshopStatus.WORKSHOP_STATUS_PROPOSED:
        txtActionButton = 'Accept';
        status = 'date proposed by admin';
        canReschedule = true;
        result.workshopWeek = confirmedProposedDate.workshopWeek;
        result.remainingDays = confirmedProposedDate.remainingDays;
        break;
      case WorkshopStatus.WORKSHOP_STATUS_FEE_APPROVAL_PENDING:
        txtActionButton = '';
        status = 'Pending fee approval'
        canReschedule = false;
        break;
      case WorkshopStatus.WORKSHOP_STATUS_PENDING_CONFIRMATION:
        txtActionButton = '';
        status = 'Pending Confirmation'
        canReschedule = false;
        if (siteVisitOrWorkshop.proposed_date) {
          result.workshopWeek = confirmedProposedDate.workshopWeek;
          result.remainingDays = confirmedProposedDate.remainingDays;
        }
        break;
      case WorkshopStatus.WORKSHOP_STATUS_CONFIRMED:
        txtActionButton = '';
        status = 'Confirmed';
        result.workshopWeek = confirmedDate.workshopWeek;
        result.remainingDays = confirmedDate.remainingDays;
        canReschedule = false;
        break;
      case WorkshopStatus.WORKSHOP_STATUS_DONE:
        txtActionButton = '';
        status = 'Done';
        result.workshopWeek = confirmedDate.workshopWeek;
        result.remainingDays = confirmedDate.remainingDays;
        canReschedule = false;
        break;
    }

    return {
      res: result,
      txtActionButton: txtActionButton,
      canReschedule: canReschedule,
      status: status
    };
  }


}
