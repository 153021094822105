import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fourzerofour',
  templateUrl: './fourzerofour.component.html',
  styleUrls: ['./fourzerofour.component.scss']
})
export class FourzerofourComponent {
  redirect(){
    window.location.href=`${environment.griha_url}/${environment.backRedirection.admin}`;
  }
}
