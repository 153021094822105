import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {ResponsePacket} from '../../model/ResponsePacket';
import {WorkshopSiteVisitModel} from '../../model/workshop/workshopSiteVisitModel';
import {ScheduleWorkshop} from '../../model/workshop.model';
import {SiteVisitRequestModel} from '../../model/sitevisit.request.model';

@Injectable({
  providedIn: 'root'
})
export class SiteVisitService {
  private FETCH_SITE_VISIT = '/sitevisitscheduling/?_id=#';
  private FETCH_SITE_VISIT_BY_PROJECT_ID = '/sitevisitscheduling/?project=#';
  private SCHEDULE_SITE_VISIT_URL = '/sitevisitscheduling';
  private REJECT_SITE_VISIT_REQUEST = '/sitevisitscheduling/#'
  private UPDATE_SITE_VISIT_REQUEST = '/sitevisitscheduling/#'
  private CONFIRM_SITE_VISIT_REQUEST = '/sitevisitscheduling/confirm/#'
  private PROPOSE_SITE_VISIT_REQUEST = '/sitevisitscheduling/propose/#'
  private RE_SCHEDULE_SITE_VISIT_URL =  '/sitevisitscheduling/#';

  constructor(private http: HttpClient) { }

  fetchById = (id: string) : Observable<ResponsePacket<WorkshopSiteVisitModel[]>> => {
    console.log(id)
    return this.http.get<ResponsePacket<WorkshopSiteVisitModel[]>>(this.FETCH_SITE_VISIT.replace('#', id))
      .pipe(
        catchError(this.handleError)
      );
  }

  fetchByProjectId = (id: string) : Observable<ResponsePacket<WorkshopSiteVisitModel[]>> => {
    console.log(id)
    return this.http.get<ResponsePacket<WorkshopSiteVisitModel[]>>(this.FETCH_SITE_VISIT_BY_PROJECT_ID.replace('#', id))
      .pipe(
        catchError(this.handleError)
      );
  }


  scheduleSitVisit = (scheduleReq: SiteVisitRequestModel) : Observable<ResponsePacket<WorkshopSiteVisitModel>> => {
    return this.http.post<ResponsePacket<WorkshopSiteVisitModel>>(this.SCHEDULE_SITE_VISIT_URL, scheduleReq)
      .pipe(
        catchError(this.handleError)
      );
  }

  reScheduleSitVisit = (siteVisitReq: SiteVisitRequestModel, id: string) : Observable<ResponsePacket<WorkshopSiteVisitModel>> => {
    return this.http.patch<ResponsePacket<WorkshopSiteVisitModel>>(this.RE_SCHEDULE_SITE_VISIT_URL.replace('#', id), siteVisitReq)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateSiteVisit = (scheduleReq: SiteVisitRequestModel, id: string) : Observable<ResponsePacket<WorkshopSiteVisitModel>> => {
    return this.http.patch<ResponsePacket<WorkshopSiteVisitModel>>(this.UPDATE_SITE_VISIT_REQUEST.replace('#', id), scheduleReq)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateStatus = (id: string, workshopStatus: string,isAccepted:boolean) : Observable<ResponsePacket<WorkshopSiteVisitModel>> => {
    console.log(id)
    return this.http.patch<ResponsePacket<WorkshopSiteVisitModel>>(this.REJECT_SITE_VISIT_REQUEST.replace('#', id), {status: workshopStatus,is_accepted:isAccepted})
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmStatus = (id: string, date: string, coord1Id: string, coord2Id: string) : Observable<ResponsePacket<WorkshopSiteVisitModel>> => {
    console.log(id)
    return this.http.patch<ResponsePacket<WorkshopSiteVisitModel>>(this.CONFIRM_SITE_VISIT_REQUEST.replace('#', id), {
      status: 'CONFIRMED',
      confirm_date : date,
      coordinator :{"_id": coord1Id},
      coordinator2 :{"_id": coord2Id}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  proposeStatus = (id: string, date: string, coord1Id: string, coord2Id: string) : Observable<ResponsePacket<WorkshopSiteVisitModel>> => {
    console.log(id)
    return this.http.patch<ResponsePacket<WorkshopSiteVisitModel>>(this.PROPOSE_SITE_VISIT_REQUEST.replace('#', id), {
      proposed_date : date,
      coordinator :{"_id": coord1Id},
      coordinator2 :{"_id": coord2Id}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError = (error: any) => {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.error;
    } else {
      // server-side error
      errorMessage = error.error.error;
    }
    return throwError(errorMessage);
  }
}
