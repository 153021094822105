import { Component, Input, Output, ViewChild, EventEmitter, AfterViewInit, ViewEncapsulation  } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../services/data-sharing.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class TableComponent{
  @Input() dataSource: MatTableDataSource<PeriodicElement>;
  @Input() canView: boolean;
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;
  @Input() canAssignCoordinator: boolean;
  @Input() canInitiateProject: boolean;
  @Input() canViewLogs: boolean;
  @Input() canViewSettings:boolean;
  @Input() pageSize:any;
  @Input() pageIndex:any;
  @Input() totalItems:any;
  displayedColumns: string[] = ['project', 'project_code', 'client', 'regDate', 'status', 'coordinator', 'actions'];
  @Output() view = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() initiate = new EventEmitter<any>();
  @Output() assignUser = new EventEmitter<any>();
  @Output() viewSettings = new EventEmitter<any>();
  @Output() updatePageChange=new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  userRole:string;
  userId:string;


  constructor(private dataSharingService:DataSharingService) {
  }

  ngOnInit() {
    console.log('this.tableData: ', this.dataSource);
    this.userRole=this.dataSharingService.clientRole;
    this.userId=this.dataSharingService.clientId;
  }

  onDelete = (rowData: any) => {
    this.delete.emit(rowData);
    console.log(rowData)
  }

  onEdit = (rowData: any) => {
    this.edit.emit(rowData);
  }

  onView = (rowData: any) => {
    this.view.emit(rowData);
  }
  onInitiate = (rowData: any) => {
    this.initiate.emit(rowData);
  }


  onAssignUser = (rowData: any) => {
    this.assignUser.emit(rowData)
  }

  onViewSettings=(rowData:any)=>{
    this.viewSettings.emit(rowData);
  }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  // }

  
  onPageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    const params:any={
      page:(this.pageIndex).toString(),
      list_size:this.pageSize.toString()
    }
    if(this.userRole==='Coordinator'){
      params.coordinator=this.userId
    }else if(this.userRole==='Client'){
      params.client=this.userId
    }else if(this.userRole==='Evaluator'){
      params.evaluator=this.userId
    }
    this.updatePageChange.emit(params);
  }
}
