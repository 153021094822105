import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(timeString: string): string {
    console.log(timeString);
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':').map(Number);
    
    // Determine if it's AM or PM
    const period = hours < 12 ? 'A.M.' : 'P.M.';

    // Convert 24-hour format to 12-hour format
    let formattedHours = hours % 12;
    formattedHours = formattedHours === 0 ? 12 : formattedHours;

    // Format minutes
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    // Return formatted time
    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

}
